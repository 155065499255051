<template>
  <ion-page class="page scrollable">
    <div>
      <div class="mt-3">
        <router-link :to="{ name: 'posts-tabs' }" class="clickable-item-hov-hov d-flex align-items-center text-black"
          ><i class="ti-angle-left mr-1" />Back to Posts</router-link
        >
      </div>

      <div class="blab-card d-flex mt-4">
        <ProfileRing
          :image="get(inActionBlab?.character, 'profile_ring.image')"
          :borderWidth="65"
          :ringTop="-7"
          :ringLeft="-7"
          class="position-absolute"
        />
        <img
          v-image
          :src="resizeUpload(get(inActionBlab?.character, 'info.cropProfilePicture', '/empty.png'), '80x80')"
        />

        <div class="ml-3 flex-grow-1">
          <div class="name text-black">
            <div class="d-flex align-items-center">
              <div>{{ inActionBlab.title }}</div>
              <MatureTag class="ml-2" size="md" v-if="isNsfw" />
              <i
                v-if="isBlabOwner"
                color="primary"
                class="ti-pencil-alt ml-2 clickable-item-hov"
                style="font-size: 20px"
                @click="edit"
              />
            </div>
          </div>
          <div v-if="!isEmpty(inActionBlab)" class="d-flex mt-1">
            <small v-if="isEmpty(inActionBlab.character)">
              <router-link
                :to="{
                  name: 'profile',
                  params: { username: get(inActionBlab, 'user.username') },
                }"
              >
                {{ get(inActionBlab, 'user.username') }}
              </router-link>
            </small>
            <small v-else>
              <router-link
                :to="{
                  name: 'character-profile-new',
                  params: { slug: inActionBlab.character.slug },
                }"
              >
                {{ get(inActionBlab.character, 'info.name') }}
              </router-link></small
            >

            <small class="ml-2">{{ formatTimeAgo(inActionBlab.created) }}</small>
            <small v-if="inActionBlab.location" class="ml-2">
              <i class="ti-location-pin icon" />
              {{ inActionBlab.location }}</small
            >
          </div>
        </div>
      </div>
      <div v-if="sanitizedDescription" class="description mt-3 blab-description" v-html="sanitizedDescription" />
      <div class="mt-2 clickable-item">
        <ion-badge v-for="tag in inActionBlab.tags" :key="tag.id" color="medium ml-1" @click="openTagSearch(tag)"
          >#{{ tag }}</ion-badge
        >
      </div>

      <div class="mt-2 d-flex justify-content-between">
        <div class="actions d-flex">
          <a href="#" @click.prevent>
            <Reaction
              type="post"
              :reactions="reactions"
              :user-reaction="userReaction"
              @changed="(reaction) => reactionChanged(reaction.key, inActionBlab, reaction.isInstant)"
            >
              <ion-button color="transparent" class="inline-button icon-button clickable">
                <div class="d-flex align-items-center justify-content-center">
                  <i
                    :color="!!userReaction && userReaction.reaction ? 'secondary' : 'primary'"
                    class="ti-thumb-up mr-2"
                  />
                  <span
                    class="reaction-count"
                    :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-primary'"
                    >{{ reactions?.total_count || 0 }}</span
                  >
                </div>
              </ion-button>
            </Reaction>
          </a>

          <ion-icon
            style="font-size: 22px; margin-top: 5px; color: var(--ion-color-primary)"
            class="ml-2 bm-picker d-inline-block"
            :icon="chatboxEllipsesOutline"
            @click.prevent="openComments"
          />
        </div>
      </div>
      <post-featured-chars :characters="inActionBlab.featured_characters" />
    </div>
    <hr class="mb-3 w-100" />

    <div v-if="!pinnedRepliesLoading">
      <template v-if="get(pinnedReplies, 'length')">
        <div class="name my-3" style="font-weight: bold; font-size: 18px">Pinned Replies</div>

        <Sortable tag="div" :list="pinnedReplies" item-key="id" handle="#blab-drag-handle" @change="dragSunshineMsg">
          <template #item="{ element, index }">
            <blab-sun-shine-messages
              :message="element"
              :blab="inActionBlab.id"
              :maindetails="true"
              :sunshine="pinnedReplies"
              :sunindex="index"
              :nestedReply="nestedReply"
              :replies-count="get(sunshineparentrepliesCounts, `${element.reply.id}.child_count`) || 0"
              class="my-2"
              @deleted="hideBlabSunShineReply"
              @afterReaction="(reactionResp) => onReactionSunShine(index, reactionResp)"
              @unshine="(reply_id) => onUnpinned(reply_id)"
              @count="fetchSunshineParentRepliesCount"
            />
          </template>
        </Sortable>
      </template>

      <div class="name my-3" style="font-weight: bold; font-size: 18px">
        {{ get(pinnedReplies, 'length') ? 'Other Replies' : 'Replies' }}
      </div>

      <div class="blab-replies">
        <div v-if="blabReplyMessage && blabReplyMessage.length">
          <div v-for="(message, index) of blabReplyMessage" :key="message.id" class="my-2">
            <blab-message
              :id="`comment-${message.id}`"
              :message="message"
              :blab="inActionBlab.id"
              :maindetails="true"
              :nestedReply="nestedReply"
              :replies-count="get(parentrepliesCounts, `${message.id}.child_count`) || 0"
              :show-new-side-line="!!get(message, `isNew`)"
              @afterReaction="(reactionResp) => onReaction(index, reactionResp)"
              @posted="fetchBlabCountReplies"
              @deleted="hideBlabReply"
              @count="fetchParentRepliesCount"
              @pinned="(res) => onPinned(index, res)"
            />
          </div>
          <div v-if="nextPageExists && !repliesLoading" class="clickable-item-hov text-center" @click="requestLoadMore">
            View Previous Replies
          </div>
        </div>

        <div v-else-if="!repliesLoading" class="ml-auto d-flex justify-content-center">
          <div>No replies yet! Be the first to respond!</div>
        </div>

        <div v-if="repliesLoading" class="d-flex justify-content-center">
          <ChLoading size="sm" class="spinner" />
        </div>
      </div>
    </div>

    <div v-else class="d-flex justify-content-center">
      <ChLoading size="sm" class="spinner" />
    </div>

    <hr class="mb-3 w-100" />
    <div v-if="isAuthenticated" class="mt-2 pb-2 ml-2">
      <post-blab
        :opendetails="true"
        :blab="inActionBlab.id"
        :is-open="isOpenComments"
        @count="fetchBlabCountReplies"
      ></post-blab>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { resizeUpload } from '@/shared/utils/upload';
import { chatboxEllipsesOutline } from 'ionicons/icons';
import PostBlab from './components/PostBlab.vue';
import BlabMessage from '@/shared/pages/blabs/components/BlabMessage.vue';
import Reaction from '@/shared/components/Reaction/index.vue';
import BlabSunShineMessages from '@/shared/pages/blabs/components/BlabSunShineMessages.vue';
import {
  getBlabReplies,
  getCountofBlabReplies,
  getCountofReplies,
  getNextPage,
  getSunShineBlabReply,
  updateSunShineOrder,
} from '@/shared/actions/blabs';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import store from '@/shared/store';
import { authStore } from '@/shared/pinia-store/auth';
import { sanitizeHtml } from '@/shared/utils/html';
import PostFeaturedChars from '@/shared/components/PostFeaturedChars.vue';
import MatureTag from '@/shared/components/MatureTag.vue';
import ProfileRing from '@/shared/components/ProfileRing.vue';

const repliesLoading = ref(false);
const blabReplyMessage = ref<any[]>([]);
const blabRepliesCount = ref<any>({});
const parentrepliesCounts = ref({});
const reorderSunshineReply = ref<any[]>([]);
const paging = ref<any>({});
const blabrepliesid = ref<string[] | undefined>([]);
const pinnedReplies = ref<any[]>([]);
const route = useRoute();
const nestedReply = ref({});
const picture = ref('');
const title = ref('');
const description = ref('');
const pageTitle = ref('');
const isPublic = ref(true);
const allowCrawlers = ref(true);
const url = ref(`https://characterhub.com${route.path}`);
const sunshineparentrepliesCounts = ref({});
const isOpenComments = ref(false);
const pinnedRepliesLoading = ref(false);
const currOffset = ref(0);
const repliesPageLimit = ref(10);

const isNsfw = computed(() => inActionBlab.value.is_nsfw);

const wsConnection = ref<WebSocket | null>(null);

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const authToken = computed(() => {
  const { authToken } = authStore();
  return authToken.value;
});

const isAuthenticated = computed(() => {
  const { isAuthenticated } = authStore();
  return isAuthenticated.value;
});

watch(blabReplyMessage, () => {
  fetchParentRepliesCount();
});

watch(pinnedReplies, () => {
  fetchSunshineParentRepliesCount();
});

const getBlab = (id: string) => store.dispatch('BlabsModule/getBlab', id);

const inActionBlab = computed(() => {
  return store.getters['BlabsModule/inActionBlab'];
});

const loading = computed(() => !inActionBlab.value || repliesLoading.value);

useCommentHighlighter(loading);

const sanitizedDescription = computed(() => {
  return sanitizeHtml(get(inActionBlab.value, 'description') || '');
});

const fetchBlabCountReplies = async () => {
  blabRepliesCount.value = {};
  blabrepliesid.value?.push(inActionBlab.value.id);
  const resp = await getCountofBlabReplies(blabrepliesid.value);
  blabRepliesCount.value = keyBy(resp, 'blab');
};

const fetchParentRepliesCount = async () => {
  parentrepliesCounts.value = {};
  const resp = await getCountofReplies(map(blabReplyMessage.value, 'id'));
  parentrepliesCounts.value = keyBy(resp, 'parent_reply_id');
};

const hideBlabSunShineReply = (id: string) => {
  pinnedReplies.value = pinnedReplies.value.filter((msg: any) => msg.id !== id);
};

const fetchSunshineParentRepliesCount = async () => {
  sunshineparentrepliesCounts.value = {};
  const resp = await getCountofReplies(map(pinnedReplies.value, 'reply.id'));
  sunshineparentrepliesCounts.value = keyBy(resp, 'parent_reply_id');
};

const openComments = () => {
  isOpenComments.value = true;
  setTimeout(() => {
    isOpenComments.value = false;
  }, 10);
};

const userReaction = computed(() => {
  if (inActionBlab.value?.user_reaction) {
    return inActionBlab.value?.user_reaction;
  }
  return null;
});

const onReactionSunShine = (index: any, reaction: any) => {
  if (!pinnedReplies.value) return;
  pinnedReplies.value[index].reply = {
    ...pinnedReplies.value[index].reply,
    ...reaction.updatedReactionsData,
  };
};

const onReaction = (replyIndex: number, reaction: any) => {
  blabReplyMessage.value[replyIndex] = {
    ...blabReplyMessage.value[replyIndex],
    ...reaction.updatedReactionsData,
  };
};

const reactions = computed(() => {
  const { reaction_counts } = inActionBlab.value || {};
  return reaction_counts;
});

const replyCount = computed(() => {
  try {
    return get(blabRepliesCount.value, `${inActionBlab.value.id}.blab_replies_count`);
  } catch (e) {
    return 0;
  }
});

const isBlabOwner = computed(() => {
  try {
    return inActionBlab.value.user!.username === user.value.username;
  } catch (error) {
    return false;
  }
});

const openTagSearch = (tagText: any) => {
  const hashText = '#';
  tagText = hashText.concat(tagText);
  const router = useRouter();
  router.push({ name: 'search', query: { term: tagText, tab: 'all' } });
};

const dragSunshineMsg = async () => {
  pinnedReplies.value.forEach((field: any, index: any) => {
    reorderSunshineReply.value[index] = {
      reply: field.reply.id,
      order_num: field.order_num,
    };
  });

  reorderSunshineReply.value.forEach((field: any, index: any) => (field.order_num = index));

  const payload = {
    blab: inActionBlab.value.id,
    reply_order: reorderSunshineReply.value,
  };

  await updateSunShineOrder(payload);
};

const fetchPinnedReplies = async (inclOtherReplies = true, scrollToReplies = false) => {
  pinnedRepliesLoading.value = true;
  pinnedReplies.value = await getSunShineBlabReply([inActionBlab.value.id]);

  if (scrollToReplies) {
    nextTick(() => {
      const document = useDocument();
      if (get(pinnedReplies.value, 'length')) {
        document.value?.getElementById(`post-pinned-replies`)?.scrollIntoView({ block: 'start' });
      } else {
        document.value?.getElementById(`post-replies`)?.scrollIntoView({ block: 'start' });
      }
    });
  }

  if (inclOtherReplies) fetchPostReplies();
  pinnedRepliesLoading.value = false;
};

const hideBlabReply = (id: string) => {
  blabReplyMessage.value = blabReplyMessage.value.filter((msg: any) => msg.id !== id);
};

const fetchPostReplies = async (offset = 0) => {
  repliesLoading.value = true;

  const { results, ...pagingRes } = await getBlabReplies(inActionBlab.value.id, offset, repliesPageLimit.value, null, {
    ordering: '-created',
    sunshined_reply_ids: map(pinnedReplies.value, (pin: any) => pin.reply.id).join(',') || null,
  });
  blabReplyMessage.value = !offset ? results : blabReplyMessage.value.concat(results);

  paging.value = pagingRes;
  currOffset.value = offset;

  repliesLoading.value = false;
};

const hookBlabMessageWS = () => {
  const {
    public: { wsUrl },
  } = useRuntimeConfig();

  if (wsConnection.value) {
    try {
      wsConnection.value.close();
    } catch (e) {
      // Handle if needed
    }
    wsConnection.value = null;
  }

  const connection = new WebSocket(`${wsUrl}/ws/blabreplies/${inActionBlab.value.id}/?token=${authToken.value}`);
  connection.onmessage = (event) => {
    const data = JSON.parse(event.data).message;

    if (data.action === 'create') {
      const newReply = { ...data.message, isNew: false };
      const isMyComment = get(newReply, 'user.id') === user.value.id;
      if (isMyComment && !data.message.parent_reply) {
        newReply.isNew = true;
        setTimeout(() => {
          const reply = blabReplyMessage.value.find((msg: any) => msg.id === newReply.id);
          reply && (reply.isNew = false);
        }, 5000);
      } else if (isMyComment) {
        nestedReply.value = newReply;
      }

      if (!data.message.parent_reply) {
        blabReplyMessage.value.unshift(newReply);
      }

      blabRepliesCount.value += 1;
      currOffset.value += 1;

      if (isMyComment) {
        nextTick(() => {
          const document = useDocument();
          document.value?.getElementById(`post-reply-${get(data.message, 'id')}`);
        });
      }
    }
  };
  connection.onerror = () => {};
  wsConnection.value = connection;
};

const nextPageExists = computed(() => {
  return !!paging.value.next;
});

const requestLoadMore = async (ev: MouseEvent) => {
  if (!paging.value.next) {
    (ev?.target as any).complete();
  } else {
    await fetchPostReplies(currOffset.value + repliesPageLimit.value);
  }
};

const reactionChanged = async (reaction: string, currBlab: any, isInstant = false) => {
  await store.dispatch('BlabsModule/react', {
    reaction,
    blab: currBlab,
    isInstant,
  });
};

const onPinned = (replyIndex: number, details: any) => {
  const splicedRep = blabReplyMessage.value.splice(replyIndex, 1);
  pinnedReplies.value.push({ reply: splicedRep[0], order_num: details.order_num, blab: details.blab });
  currOffset.value -= 1;
};

const onUnpinned = async (_reply_id: any) => {
  await fetchPinnedReplies();
};

const getBlabDetails = async () => {
  const router = useRouter();
  await getBlab(String(router.currentRoute.value.params.id));
  pageTitle.value = `${inActionBlab.value?.title} - CharacterHub`;
  description.value = `Read more about ${inActionBlab.value?.title} on CharacterHub!`;
  title.value = `${inActionBlab.value?.title}`;
  allowCrawlers.value = (inActionBlab?.value?.reaction_counts?.total_count || 0) > 200;

  useChHead(pageTitle, title, description, url, picture, allowCrawlers);

  fetchBlabCountReplies();
  fetchPinnedReplies();
};

const edit = () => {
  const router = useRouter();
  router.push({
    name: 'edit-post',
    params: { id: router.currentRoute.value.params.id },
  });
};

await useChAsyncData(async () => {
  await getBlabDetails();
  if (get(inActionBlab.value, 'id')) {
    if (process.client) {
      hookBlabMessageWS();
    }
  }
});

onBeforeUnmount(() => {
  if (wsConnection.value) {
    try {
      wsConnection.value.close();
    } catch (e) {
      // Handle if needed
    }
    wsConnection.value = null;
  }
});
</script>

<style lang="sass" scoped>
.name
  font-size: 18px
  font-weight: bold

  .name:hover
    opacity: 0.7

.actions
  max-width: 200px
.inline-button
  &:not(:first-of-type)
    margin: 0 5px

.active
  color: black
  font-weight: bold
.normal
  color: grey

.blab-card
  img
    width: 50px
    height: 50px
    border-radius: 25px
    border: solid gray 0.1px
    object-fit: cover

  .description
    font-size: 16px
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
</style>
